/**
 * Password validity indicator.
 * For use in forms where user password is set.
 */
export default {
  name: 'PasswordValidFeedback',
  props: {
    newPasswordIsValid: {
      type: Object,
    },
    passwordPolicy: {
      type: Object,
    },
    requirementsText: {
      type: String,
      default: 'profile_page_validation_must_include',
    },
  },
};
