/**
 * Create user.
 * TODO - Enable client to be specified in URL as part of the create client workflow
 */
import { apiGet, apiPost } from '@/util/api';
import { passwordIsValid } from '@/util/auth';

import PasswordValidFeedback from '@/components/PasswordValidFeedback.vue';

export default {
  name: 'UserNew',
  components: {
    PasswordValidFeedback,
  },
  data() {
    return {
      loading: true,
      clients: [],
      roles: [
        { value: 'USER', text: this.$t('USER') },
        { value: 'CURATOR', text: this.$t('CURATOR') },
        { value: 'SUPERADMIN', text: this.$t('SUPERADMIN') },
      ],
      name: '',
      nameState: null,
      email: '',
      emailState: null,
      role: 'USER',
      isActive: true,
      client: null,
      clientForced: false,
      clientForcedName: '',
      password: '',
      passwordState: null,
      passwordPolicy: null,
    };
  },
  async created() {
    // Get the list of clients.
    try {
      const clients = await apiGet('/client');
      this.clients = clients.map(c => {
        return { value: c.id, text: c.name };
      });
      if (this.clients.length) {
        this.client = this.clients[0].value;
      }
    } catch (error) {
      this.$bvToast.toast(this.$t('user_error_get_clients'), {
        title: this.$t('common_error_title'),
        variant: 'danger',
      });
    }

    // Get the Cognito password policy.
    try {
      this.passwordPolicy = await apiGet('/user/password-policy');
    } catch (error) {
      this.$bvToast.toast(this.$t('user_error_get_password_policy'), {
        title: this.$t('common_error_title'),
        variant: 'danger',
      });
    }

    /**
     * Set the form client if one is specified in the URL
     */
    if (this.$route.params.clientId) {
      this.client = this.$route.params.clientId;
      this.clientForced = true;

      this.$bvToast.toast(this.$t('client_success_created'), {
        title: this.$t('common_success_title'),
        variant: 'success',
      });

      const forcedClient = this.clients.find(c => {
        return c.value == this.client;
      });
      this.clientForcedName = forcedClient.text;
    }

    this.loading = false;
  },
  computed: {
    /**
     * Determines whether the form is valide and may be submitted.
     */
    formValid() {
      return (
        this.nameState &&
        this.emailState &&
        this.passwordState &&
        ((this.role === 'USER' && this.client) || // user has USER role and a client is set
          (this.role !== 'USER' && !this.client)) // user has CURATOR or SUPERADMIN role and a client is not set
      );
    },

    /**
     * Determine whether the entered password is valid.
     */
    newPasswordIsValid() {
      return passwordIsValid(this.passwordPolicy, this.password);
    },
  },
  methods: {
    /**
     * Check the validity of the indicated input and set its state.
     * @param ref
     */
    checkInputValid(ref) {
      this[`${ref}State`] = this.$refs[ref].checkValidity();
    },

    /**
     * If the role is changed, ensure that the client field is valid.
     */
    onChangeRole() {
      if (['CURATOR', 'SUPERADMIN'].includes(this.role)) {
        this.client = null;
      } else {
        // USER role
        this.client = this.clients[0].value;
      }
    },

    /**
     * Set the password input valid state.
     */
    checkPasswordValid() {
      this.passwordState = !!(
        this.password &&
        this.newPasswordIsValid &&
        this.newPasswordIsValid.All
      );
    },

    /**
     * Create a new user.
     */
    async createUser() {
      if (this.formValid) {
        this.loading = true;
        const userData = {
          name: this.name,
          email: this.email,
          role: this.role,
          isActive: this.isActive,
          client: this.client,
          password: this.password,
        };
        await apiPost('/user', userData)
          .then(userData => {
            // On success, navigate to the new user's edit page, or to the client edit page if client is forced.
            if (this.clientForced) {
              this.$router.push({
                name: 'client-edit',
                params: { clientId: this.$route.params.clientId, created: 1 },
              });
            } else {
              this.$router.push({
                name: 'user-edit',
                params: { userId: userData.userId, created: 1 },
              });
            }
          })
          .catch(error => {
            let errorCode = '';
            try {
              errorCode = error.response.data.code;
            } catch {
              // Do nothing
            }
            if (errorCode && errorCode === 'UsernameExistsException') {
              // The e-mail address is already in use - show this as a specific error
              this.emailState = false;
              this.$bvToast.toast(
                this.$t('new_user_error_email_already_in_user'),
                {
                  title: this.$t('common_error_title'),
                  variant: 'danger',
                },
              );
            } else {
              // Other error - just show some generic indication
              this.$bvToast.toast(this.$t('new_user_error_creating_user'), {
                title: this.$t('common_error_title'),
                variant: 'danger',
              });
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
